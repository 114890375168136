$lg-height: var(--large-input-height);
$lg-font-size: var(--large-input-font-size);

$sm-height: var(--small-input-height);
$sm-font-size: var(--small-input-font-size);

$left-border-radius-8: 8px 0 0 8px;

$input-selector-select-value: var(--input-selector-text-search-type);

$bg-gray-palette: var(--input-selector-select-bg);

mat-form-field.mat-mdc-form-field {
  width: 100% !important;

  .mdc-text-field--outlined:not(.mdc-text-field--focused, .mdc-text-field--invalid, .mdc-text-field--disabled) {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: var(--input-selector-border-enable) !important;
    }
  }

  .mdc-text-field--outlined {
    .mdc-notched-outline__leading {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }

    .mdc-notched-outline__trailing {
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }
  }

  input::placeholder,
  .mat-mdc-select-placeholder {
    color: var(--input-selector-text-hint) !important;
  }

  .mat-mdc-form-field-icon-suffix,
  .mat-mdc-form-field-icon-prefix {
    color: var(--input-selector-icon-enable) !important;
  }

  .mat-mdc-select-arrow {
    color: var(--input-selector-chevron) !important;
  }

  &.large {
    .mat-mdc-form-field-infix,
    .mat-mdc-form-field-flex {
      min-height: $lg-height !important;
    }

    input {
      height: $lg-height !important;
      font-size: $lg-font-size !important;

      ::placeholder {
        color: var(--input-selector-text-hint) !important;
        font-size: $lg-font-size !important;
      }
    }

    mat-select {
      height: $lg-height !important;
      display: flex !important;

      .mat-mdc-select-placeholder,
      .mat-mdc-select-arrow,
      .mat-mdc-select-value {
        font-size: $lg-font-size !important;
      }

      .mat-mdc-select {
        background-color: var(--input-selector-bg) !important;
        border-radius: $left-border-radius-8 !important;

        .mat-mdc-select-value {
          color: var(--input-selector-text-filled) !important;
          font-size: $lg-font-size !important;
        }
      }
    }

    ggp-input-selector {
      mat-select {
        width: fit-content;
        background-color: $bg-gray-palette !important;
        border-radius: $left-border-radius-8 !important;
        margin-right: 12px !important;
        padding: 0 12px !important;

        .mat-mdc-select-value {
          color: $input-selector-select-value !important;
          font-size: $lg-font-size !important;
        }

        .mat-mdc-select-arrow {
          margin-left: 8px !important;
        }
      }
      .search-types {
        width: fit-content;
        background-color: $bg-gray-palette !important;
        border-radius: $left-border-radius-8 !important;
        margin-right: 12px !important;
        padding: 0 12px !important;

        .mat-mdc-select-value {
          color: $input-selector-select-value !important;
          font-size: $lg-font-size !important;
        }

        .mat-mdc-select-arrow {
          margin-left: 8px !important;
        }
      }

      .keywords-options {
        width: fit-content;
        height: 16px;
        border-radius: $left-border-radius-8 !important;
        padding: 0 12px !important;
        background-color: var(--input-selector-bg-white) !important;
        .mat-mdc-select-value {
          color: var(--input-selector-text-filled) !important;
          font-size: $lg-font-size !important;
          font-style: normal;
          font-weight: 400;
          letter-spacing: 0.4px;
        }

        .mat-mdc-select-arrow {
          margin-left: 8px !important;
        }
        .mat-mdc-select-placeholder {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
  }

  &.small {
    .mat-mdc-form-field-infix,
    .mat-mdc-form-field-flex {
      min-height: $sm-height !important;
    }

    .mat-mdc-form-field-icon-suffix mat-icon,
    .mat-mdc-form-field-icon-prefix mat-icon {
      font-size: 20px !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    input {
      height: $sm-height !important;
      font-size: $sm-font-size !important;

      ::placeholder {
        color: var(--input-selector-text-hint) !important;
        font-size: $sm-font-size !important;
      }
    }

    mat-select {
      height: $sm-height !important;
      display: flex !important;

      .mat-mdc-select-placeholder,
      .mat-mdc-select-arrow,
      .mat-mdc-select-value {
        font-size: $sm-font-size !important;
      }

      .mat-mdc-select {
        background-color: var(--input-selector-bg) !important;
        border-radius: $left-border-radius-8 !important;

        .mat-mdc-select-value {
          color: var(--input-selector-text-hint) !important;
          font-size: $sm-font-size !important;
        }
      }
    }

    ggp-input-selector {
      mat-select {
        width: fit-content;
        background-color: $bg-gray-palette !important;
        border-radius: $left-border-radius-8 !important;
        margin-right: 12px !important;
        padding: 0 12px !important;

        .mat-mdc-select-value {
          color: $input-selector-select-value !important;
        }

        .mat-mdc-select-arrow {
          margin-left: 8px !important;
        }
      }
      .search-types {
        width: fit-content;
        background-color: $bg-gray-palette !important;
        border-radius: $left-border-radius-8 !important;
        margin-right: 12px !important;
        padding: 0 12px !important;

        .mat-mdc-select-value {
          color: $input-selector-select-value !important;
        }

        .mat-mdc-select-arrow {
          margin-left: 8px !important;
        }
      }
      .keywords-options {
        margin-right: -15px !important;
        width: fit-content;
        height: 16px;
        border-radius: $left-border-radius-8 !important;
        padding: 0 12px !important;
        background-color: var(--input-selector-bg-white) !important;
        .mat-mdc-select-value {
          color: var(--input-selector-text-filled) !important;
          font-size: $sm-font-size !important;
          font-style: normal;
          font-weight: 400;
          letter-spacing: 0.4px;
        }

        .mat-mdc-select-arrow {
          margin-left: 8px !important;
        }

        .mat-mdc-select-placeholder {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
  }

  &.large .mat-mdc-form-field-error-wrapper .mat-mdc-form-field-error {
    font-size: $sm-font-size !important;
  }

  &.small .mat-mdc-form-field-error-wrapper .mat-mdc-form-field-error {
    font-size: 10px !important;
  }

  &.large,
  &.small {
    .mat-mdc-form-field-icon-prefix mat-icon {
      padding: 0 12px 0 12px !important;
    }

    .mat-mdc-text-field-wrapper {
      background-color: var(--input-selector-bg-white) !important;
    }

    .mat-mdc-form-field-subscript-wrapper {
      margin-bottom: 4px !important;

      .mat-mdc-form-field-error-wrapper {
        padding: 0 !important;

        .mat-mdc-form-field-error {
          float: right !important;
          display: flex !important;
        }
      }
    }

    &.mat-form-field-disabled:has(ggp-input-selector) .mat-mdc-select {
      background-color: var(--input-selector-text-disalbed) !important;

      .mat-mdc-select-placeholder,
      .mat-mdc-select-arrow,
      .mat-mdc-select-value {
        color: var(--light-primary-text) !important;
      }
    }

    .mdc-text-field:has(ggp-input-selector) {
      padding-left: 0 !important;
    }
  }

  &.mat-form-field-disabled,
  &.mat-form-field-disabled .mat-mdc-form-field-icon-suffix,
  &.mat-form-field-disabled .mat-mdc-form-field-icon-prefix,
  &.mat-form-field-disabled input::placeholder,
  &.mat-form-field-disabled .mat-mdc-select-placeholder,
  &.mat-form-field-disabled .mat-mdc-select-arrow,
  &.mat-form-field-disabled .mat-mdc-select-value {
    color: var(--input-selector-text-icon-disable) !important;
  }
}

.mat-mdc-select-panel .mat-mdc-option-active:not(.mat-mdc-option-multiple) {
  background-color: transparent !important;

  span {
    font-weight: 700 !important;
  }

  mat-pseudo-checkbox {
    display: none !important;
  }
}

.mat-mdc-select-panel span,
.mat-mdc-select-panel .mat-mdc-option-multiple span,
.mat-mdc-select-panel .mat-mdc-option-active:not(.mat-mdc-option-multiple) span {
  color: var(--input-selector-dropdown-list-text-enable) !important;
  font-size: 14px !important;
}

mat-option {
  flex-direction: row-reverse !important;

  mat-pseudo-checkbox {
    margin-right: 0 !important;
  }
}

.mdc-text-field__input {
  display: block !important;
}

.mat-mdc-form-field-infix {
  padding: unset !important;
}

.cdk-overlay-container .cdk-overlay-pane:has(.filter-panel) {
  width: 100% !important;

  .filter-panel {
    width: 197px;
    padding: unset;
    position: inherit !important;
    top: 8px;
    border-radius: 8px;
    height: auto;
    max-height: none;
    overflow: hidden;
    mat-option {
      height: 20px;
      padding: 0 12px;
    }
    .toggle {
      display: flex;
      align-items: center;
      gap: 8px;
      height: 40px;
      padding: 12px 12px 16px 12px;
      margin: 0 0 16px;
      border-bottom: 1px solid var(--notifications-divider-line);
    }
  }
}

.cdk-overlay-container .cdk-overlay-pane:has(.tags-panel) {
  width: 100% !important;

  .tags-panel {
    width: 245px;
    padding: unset;
    position: inherit !important;
    top: 8px;
    border-radius: 8px;
    height: auto;
    overflow: hidden;
    max-height: 288px;
    overflow-y: auto;

    &:has(.empty-tags) {
      width: 186px;

      .empty-tags {
        height: 48px;
        padding: 12px;

        .mat-pseudo-checkbox {
          display: none;
        }
      }
    }

    mat-option {
      height: 20px;
      padding: 0 12px;
    }
  }
}

.cdk-overlay-container .cdk-overlay-pane:has(.type-of-search-panel) {
  width: 100% !important;

  .type-of-search-panel {
    width: 94px;
    height: 152px;
    padding: 12px;
    position: relative;
    top: 7px;
  }
}

.cdk-overlay-container .cdk-overlay-pane:has(.keywords-options-panel) {
  width: 100% !important;

  .keywords-options-panel {
    width: 100px;
    padding: 0;
    top: 135px;
    border-radius: 8px;
    right: 544px;
    position: fixed;
    mat-option {
      height: 20px;
      padding: 0 12px;
    }
  }
}

.cdk-overlay-container .cdk-overlay-pane:has(.search-types-panel) {
  width: 100% !important;

  .search-types-panel {
    width: 207px;
    padding: 0;
    top: 7px;
    border-radius: 8px;
    position: relative;
    mat-option {
      height: 20px;
      padding: 0 12px;
    }
  }
}
