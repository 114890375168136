// Plus imports for other components in your app.
@use '@angular/material' as mat;
@use './styles/theme/palette' as palette;
@import './styles/';

/* You can add global styles to this file, and also import other style files */

:root {
  --dark-primary-text: rgba(black, 0.87);
  --light-primary-text: white;
  --space--xlg: 16px;
  --space--2xlg: 24px;
  --border-radius: 8px;
  --map-border-radius: 8px;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.subtitle_link {
  cursor: pointer;
  color: mat.m2-get-color-from-palette(palette.$primary-palette, 700);
}

.date-box {
  &.updated {
    background-color: var(--project-list-view-status-chips-updated);
  }
  &.deadline-10 {
    background-color: var(--project-list-view-status-chips-deadline-10);
  }
  &.deadline-30 {
    background-color: var(--project-list-view-status-chips-deadline-10);
  }
  color: var(--project-list-view-status-chips-text);
  width: 72px;
  height: 28px;
  border-radius: 8px;
  margin: 8px 0;
  padding: 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.expired-date {
  color: var(--project-list-view-status-chips-deadline-10);
}

.filter-layout {
  margin-left: 80px;

  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 8px !important;
    overflow: hidden;
  }
}

.marker {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    width: 0;
    height: 0;
    margin: 0 auto;
    bottom: -5px;
    border-top: 10px solid;
    border-left: 10px solid transparent;
    border-right: 11px solid transparent;
  }
}

.location-icon {
  display: flex;
  align-items: flex-start;
  gap: 8px;

  svg-icon {
    margin-top: 2px;
  }
}

.map-pin-icon {
  color: var(--map-pin);
  flex-shrink: 0;
}

.space-elements {
  margin-bottom: 16px;
}

.custom-layout-container .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 8px 0 0 8px !important;
}

.dialog-style,
.dialog-style-expanded {
  .mat-mdc-dialog-container .mat-mdc-dialog-surface {
    padding: 24px !important;
    border-radius: 8px !important;
  }
}

.dialog-style-expanded {
  margin: auto !important;
}

.save-search-layout {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 8px !important;
    padding: 24px;
  }
}

.backdropBackground {
  background: var(--general-components-bg-shadow);
  opacity: 0.4 !important;
}

.sharing-form-panel {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 8px !important;
    overflow: hidden;
  }
}

.highlight-keyword {
  background-color: var(--project-list-view-matched-keyword-dce-docs);
  width: fit-content;
}

.mat-mdc-autocomplete-panel {
  .mat-mdc-option {
    .mdc-list-item__primary-text {
      display: flex;
      align-items: center;
    }
  }
}
