.mat-mdc-standard-chip {
  background-color: var(--notifications-chip-bg) !important;
  border-radius: 8px !important;
  &:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label,
  button {
    display: flex;
    align-items: center;
    color: var(--notifications-chip-text-enable) !important;
  }

  :hover {
    color: var(--notifications-chip-text-hover);
  }
}

.chip-centered .mdc-evolution-chip-set__chips {
  align-items: center;
}

.search-autocomplete .search-autocomplete-panel {
  border-radius: 8px !important;
  box-shadow: 1px 2px 16px 0 rgba(0, 0, 0, 0.16) !important;
  position: absolute !important;
  width: calc(100% - 16px) !important;
  top: -3px;
  margin-left: 8px;
  margin-right: 8px;

  mat-pseudo-checkbox {
    display: none !important;
  }
}
